@import 'src/styles/library/index';

.Root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: {
        image: $tableGradient;
        color: var(--app-background);
    }

    & .Mock {
        position: absolute;
        z-index: $zIndexHands + 10;
        top: 0;
        margin: {
            left: 4 * $basePadding;
        }
        text-align: right;

        & button {
            margin: 0 !important;
        }
    }

    & .Settings {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &.isBlockedByTeacher {
        & .Tray {
            & .justMe {
                & .buttonsCenter {
                    & button {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    @media (min-aspect-ratio: #{$dynamicRatioWidth}/#{$dynamicRatioHeight}) {
        --dynamic-ratio: var(--vh, 1vh) / #{($dynamicBase * $dynamicRatioHeight / $dynamicRatioWidth)};
    }
}
