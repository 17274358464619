@import 'src/styles/library/index';

.Chat {
    width: 100%;
    height: 100%;

    & .chat {
        position: absolute;
        top: 0;
        right: 0;
        bottom: vw($baseButtonHeight);
        left: 0;
        color: $colorFontInverted;
        word-break: break-word;
        overflow: auto;
    }

    & .chatEntry {
        $radius: 2 * $baseBorderRadius;

        position: relative;
        width: 75%;
        margin: {
            top: vw($basePadding);
        }
        padding: vw($basePadding);
        border: {
            radius: $radius $radius $radius 0;
            width: 1px;
            style: solid;
            color: transparentize($colorFontInverted, 0.7);
        }
        background: rgba(0, 0, 0, 0.3);
        font: {
            size: vw(22px);
        }
        line-height: 1.2;

        &.isMe {
            margin: {
                left: auto;
            }
            border: {
                radius: $radius $radius 0 $radius;
            }
        }

        & header {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: {
                bottom: vw($basePadding);
            }
        }

        & .sender {
            font: {
                weight: 700;
            }
        }

        & .time {
            font: {
                size: 0.8em;
            }
        }
    }

    & .sendMessage {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
    }

    & .input {
        flex: 1 1 auto;
        margin: {
            right: vw($basePadding);
        }
    }

    & input {
        @include dynInput;

        width: 100%;

        @include placeholder {
            position: relative;
            top: vw(-2px);
            color: $colorFontInverted;
            opacity: 0.5;
            padding: {
                left: vw(5px);
            }
            font: {
                size: vw(18px);
            }
        }
    }

    & button {
        @include resetButtonToIconOnly;
        @include buttonIconOnly($icon-paperplane);

        flex: {
            grow: 0;
            shrink: 0;
            basis: vw($baseButtonHeight);
        }
        color: white !important;
        margin: 0 !important;
    }

    & .toggleDirectorMessage {
        @include buttonIconOnly($icon-question);

        &.isDirectorMessage {
            background: white;

            &:before {
                color: black;
            }
        }
    }
}
