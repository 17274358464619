@import 'src/styles/library/index';

.Trick {
    @include centerHV;

    position: absolute;
    width: 100%;
    height: 100%;

    & .cardLane {
        $margin: 5%;
        $shiftV: 2%;
        $shiftH: 0.5%;
        $laneSize: 44%;

        display: flex;

        &:after {
            content: '';
            display: block;
            animation-duration: $baseTransitionTime;
        }

        & .Card {
            width: vw($baseCardWidth);
            //animation-duration: $baseTransitionTime;
            //animation-name: rotateCard;
            //transform: rotate(0);

            //@keyframes rotateCard {
            //    0% {
            //        transform: rotate(180deg);
            //        opacity: 0;
            //    }
            //    100% {
            //        transform: rotate(0);
            //        opacity: 1;
            //    }
            //}
        }

        &.top,
        &.bottom {
            @include centerH;

            justify-content: flex-end;
            position: absolute;

            &:after {
                height: 0;
                animation-name: vertical;

                @keyframes vertical {
                    0% {
                        height: 100%;
                    }
                    100% {
                        height: 0;
                    }
                }
            }
        }

        &.right,
        &.left {
            @include centerV;

            justify-content: flex-end;
            position: absolute;

            &:after {
                width: 0;
                animation-name: horizontal;

                @keyframes horizontal {
                    0% {
                        width: 100%;
                    }
                    100% {
                        width: 0;
                    }
                }
            }
        }

        &.top {
            flex-direction: column;
            top: $margin;
            bottom: 50% - $shiftV;
            left: 50% - $shiftH;
        }

        &.right {
            flex-direction: row-reverse;
            right: $margin;
            left: 50% + $shiftH;
            top: 50% - $shiftV / 2;
        }

        &.bottom {
            flex-direction: column-reverse;
            bottom: $margin;
            top: 50% - $shiftV;
            left: 50% + $shiftH;
        }

        &.left {
            flex-direction: row;
            left: $margin;
            right: 50% + $shiftH;
            top: 50% + $shiftV / 2;
        }
    }

    &.closed {
        @include centerVH;

        transition: $baseTransition;
        transform: translateX(-50%) translateY(-50%) scale(0.7);
    }
}
