@import 'src/styles/library/index';

.LabeledInput {
    display: flex;
    margin: {
        bottom: 2 * $basePadding;
    }
    font: {
        size: 18px;
    }

    & .label {
        color: $colorFontInverted;
        font: {
            size: 1em;
        }
    }

    &.text,
    &.password,
    &.number,
    &.email {
        flex-direction: column-reverse;

        & input {
            padding: $basePadding;
            margin: {
                top: $basePadding/2;
            }
            border: {
                width: 2px;
                style: solid;
                color: $colorFontInverted;
                radius: $baseBorderRadius;
            }
            font: {
                size: 1em;
            }
            background: black;
            color: $colorFontInverted;
            box-shadow: 0 0 0 50px black inset;
            -webkit-box-shadow: 0 0 0 50px black inset; /*your box-shadow*/
            -webkit-text-fill-color: $colorFontInverted;
            outline: none;

            &:-webkit-autofill {
                &,
                &:focus {
                    -webkit-box-shadow: 0 0 0 50px black inset; /*your box-shadow*/
                    -webkit-text-fill-color: $colorFontInverted;
                }
            }
        }
    }

    &.error {
        &.text,
        &.password,
        &.number,
        &.email {
            & input {
                box-shadow: 0 0 0 50px #400 inset;
            }
        }
    }

    &.checkbox {
        align-items: center;

        & .label {
            &:before {
                @include icon($icon-checkbox-unchecked);

                margin: {
                    right: $basePadding;
                }
            }
        }

        & input {
            display: none;

            &:checked {
                & ~ .label {
                    &:before {
                        @include icon($icon-checkbox-checked);
                    }
                }
            }
        }
    }

    & .error {
        margin: {
            top: $basePadding/2;
            left: $basePadding;
        }
        font-size: 14px;
    }

    & input {
        &[disabled] {
            & + .label {
                opacity: 0.3;
            }
        }

        &[required] {
            & + .label {
                &:after {
                    content: '*';
                }
            }
        }
    }
}
