@import 'src/styles/library/index';

.BoardStats {
    display: block;
    &.NoStats {
        visibility: collapse;
    }
    & .table {
        display: table;
        margin: auto;
        font-size: 20px;
        font-weight: 500;

        & > * {
            display: table-row;

            &.isMe {
                & > * {
                    background: rgba(255, 255, 0, 0.5);
                }
            }

            &:first-child {
                font-size: 0.85em;
                font-weight: 600;
                text-align: center;
            }

            &:nth-child(even) {
                background: lightgray;
            }

            & > * {
                display: table-cell;
                white-space: nowrap;
                padding: 0.3em;
            }
        }
    }

    & .declarer,
    & .lead {
        text-align: center;
    }

    & .tricks,
    & .players {
        text-align: right;
    }

    & .score {
        text-align: left;
    }
    & .result {
        text-align: right;
    }

    & .lead {
        & div {
            @include suits;

            display: flex;
            align-items: baseline;
            font-size: 0.85em;

            & span {
                margin-left: 0.05em;
                font-size: 1.2em;
                color: black;
            }
        }
    }

    & .contract {
        & > div {
            @include suits;

            display: inline-flex;
            align-items: baseline;
            margin: auto;
            font-size: 0.85em;

            & .level,
            & .notrump,
            & .x {
                font-size: 1.2em;
            }

            &:before {
                order: 2;
            }

            & .notrump {
                order: 3;
            }

            & .level {
                order: 1;
                color: black;
            }

            & .x {
                order: 4;
            }
        }
    }
}
