@function getTop($row, $col, $tableGrid) {
    @if ($row == top) {
        @return 0;
    } @else if($row == middle) {
        @return nth(map_get($tableGrid, $col), 1) * 1%;
    } @else {
        @return (100 - nth(map_get($tableGrid, $col), 2)) * 1%;
    }
}

@function getBottom($row, $col, $tableGrid) {
    @if ($row == top) {
        @return (100 - nth(map_get($tableGrid, $col), 1)) * 1%;
    } @else if($row == middle) {
        @return nth(map_get($tableGrid, $col), 2) * 1%;
    } @else {
        @return 0;
    }
}

@function getRight($row, $col, $tableGrid) {
    @if ($col == left) {
        @return (100 - nth(map_get($tableGrid, $row), 1)) * 1%;
    } @else if($col == center) {
        @return nth(map_get($tableGrid, $row), 2) * 1%;
    } @else {
        @return 0;
    }
}

@function getLeft($row, $col, $tableGrid) {
    @if ($col == left) {
        @return 0;
    } @else if($col == center) {
        @return nth(map_get($tableGrid, $row), 1) * 1%;
    } @else {
        @return (100 - nth(map_get($tableGrid, $row), 2)) * 1%;
    }
}

$tableSlotRows: (top, middle, bottom);
$tableSlotCols: (left, center, right);

@mixin tableGrid($partialTableGrid: ()) {
    // contains the widths of left/right col elements in %
    // contains heights of top/bottom row elements in %
    // rest can be calculated
    $defaultTableGrid: (
        // rows/widths
        top: (32, 32),
        middle: (36, 36),
        bottom: (20, 20),
        // cols/heights
        left: (35, 40),
        center: (30, 40),
        right: (35, 40)
    );

    $tableGrid: map_merge($defaultTableGrid, $partialTableGrid);

    @each $row in $tableSlotRows {
        & .#{$row} {
            @each $col in $tableSlotCols {
                &-#{$col} {
                    top: getTop($row, $col, $tableGrid);
                    bottom: getBottom($row, $col, $tableGrid);
                    left: getLeft($row, $col, $tableGrid);
                    right: getRight($row, $col, $tableGrid);
                }
            }
        }
    }

    & .Trick {
        $left: nth(map_get($tableGrid, middle), 1);
        $right: nth(map_get($tableGrid, middle), 2);
        $top: nth(map_get($tableGrid, center), 1);
        $bottom: nth(map_get($tableGrid, center), 2);
        left: (50 + $left/2 - $right/2) * 1%;
        top: (50 + $top/2 - $bottom/2) * 1%;

        &.closed {
            $right: nth(map_get($tableGrid, bottom), 2);
            $bottom: nth(map_get($tableGrid, right), 2);
            left: (100 - $right/2) * 1%;
            top: (100 - $bottom/3 * 2) * 1%;
        }
    }
}

@mixin tableIndexes($indexes) {
    @each $row in $tableSlotRows {
        & .#{$row} {
            $indexRow: nth($indexes, index($tableSlotRows, $row));
            @each $col in $tableSlotCols {
                &-#{$col} {
                    z-index: nth($indexRow, index($tableSlotCols, $col));
                }
            }
        }
    }
}
