@import 'src/styles/library/index';

.Settings {
    z-index: $zIndexSettings;
    width: 0;
    height: 0;

    @mixin colorSelect {
        $size: 20px;

        display: flex;
        margin: {
            left: 2 * $basePadding;
            bottom: 2 * $basePadding;
        }

        & > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $size;
            height: $size;
            margin: {
                left: $size;
            }
            border: 1px solid black;

            &.selected {
                &:after {
                    @include icon($icon-checkmark);

                    position: relative;
                    color: $colorFontInverted;
                    font: {
                        size: 12px;
                    }
                    text-shadow: 0 0 1px #000;
                }
            }
        }
    }

    & .buttons {
        display: flex;
        justify-content: flex-end;
        flex: 1 1 100% !important;

        & button {
            cursor: pointer;
            font-size: 24px;
            margin: {
                left: vw(10px);
            }
        }
    }

    & .settings {
        position: fixed;
        pointer-events: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.95);
        opacity: 0;
        transition: $baseTransition;
        overflow: auto;

        & > div {
            flex: 0 0 auto;
            margin: 2 * $basePadding;
            padding: 2 * $basePadding;
            border: {
                width: 1px;
                style: solid;
                color: $colorGrey;
                radius: $baseBorderRadius;
            }
            background: white;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        }

        &.show {
            pointer-events: all;
            opacity: 1;
        }
    }

    & h3 {
        margin: {
            bottom: 2 * $basePadding;
        }
    }

    & .selectSuitOrder,
    & .selectSuitColors {
        & label {
            display: flex;
            align-items: center;
            margin: $basePadding 0;
            cursor: pointer;
        }

        & input {
            margin: {
                right: $basePadding;
            }
        }

        & .label {
            margin: {
                left: $basePadding/2;
                right: $basePadding;
            }
        }

        & .suit {
            @include suits;
        }
    }

    & .selectSuitOrder {
        & .suit {
            margin: {
                left: $basePadding/4;
                right: $basePadding/4;
            }
            font: {
                size: 0.9em;
            }
        }
    }

    & .selectSuitColors {
        margin: {
            bottom: 30px;
        }

        & .suit {
            margin: {
                left: $basePadding/2;
                right: $basePadding/2;
            }
            font: {
                size: 1.2em;
            }
        }
    }

    & .selectCardBack {
        max-width: 300px;

        & .cardBacks {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            & div {
                $checkMarkSize: 20px;

                position: relative;
                width: 40px;
                height: 50px;
                margin: $basePadding;
                border: {
                    radius: $baseBorderRadius;
                    width: 1px;
                    style: solid;
                    color: $colorGrey;
                }
                background: {
                    position: center;
                    size: contain;
                    repeat: no-repeat;
                }
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
                cursor: pointer;

                &.selected {
                    &:after,
                    &:before {
                        @include centerVH;

                        position: absolute;
                        top: 0;
                        left: 100%;
                    }

                    &:before {
                        content: '';
                        width: $checkMarkSize;
                        height: $checkMarkSize;
                        border: {
                            radius: 50%;
                            width: 1px;
                            style: solid;
                            color: $colorGrey;
                        }
                        background: $colorSuccess;
                    }

                    &:after {
                        @include icon($icon-checkmark);

                        font: {
                            size: $checkMarkSize/2;
                        }
                        color: $colorFontInverted;
                    }
                }
            }
        }
    }

    & .LabeledInput {
        & .label {
            color: $colorFont;
        }
    }

    & .trayBackgrounds {
        @include colorSelect;

        margin: {
            top: -$basePadding;
        }
    }

    & .tableBackgrounds {
        @include colorSelect;

        margin: {
            top: $basePadding;
        }

        & > div {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: {
                    image: $tableGradient;
                }
                opacity: 0.5;
            }
        }
    }
}
