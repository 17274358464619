@import 'src/styles/library/index';

:root {
    // css variables
    --card-color-diamonds: #ff0000;
    --card-color-hearts: #ff0000;
    --card-color-spades: #000000;
    --card-color-clubs: #000000;
    --card-color-background: #ffffff;
    --dynamic-ratio: #{100vw / $dynamicBase};
    --vh: 100vh;
    --transition-time: 400ms;
}

html,
body {
    height: 100%;
    width: 100%;
    min: {
        width: auto !important;
        height: auto !important;
    }

    color: $colorFont !important;
}

html {
    font-size: vw(16px) !important;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Jost', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font: {
        weight: 700;
    }
}

h1 {
    font: {
        size: vw(16px * 1.5);
    }
}

h2 {
    font: {
        size: vw(16px * 1.4);
    }
}

h3 {
    font: {
        size: vw(16px * 1.3);
    }
}

h4 {
    font: {
        size: vw(16px * 1.2);
    }
}

h5 {
    font: {
        size: vw(16px * 1.1);
    }
}

h6 {
    font: {
        size: vw(16px);
    }
}

button {
    &[type='button'],
    &[type='submit'] {
        @include button;

        & + & {
            margin: {
                left: $basePadding;
            }
        }
    }
}
