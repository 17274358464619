@mixin outline($color, $width: px(1)) {
    text-shadow: (
        (-$width) (-$width) 0 $color,
        (-$width) 0 0 $color,
        (-$width) $width 0 $color,
        0 (-$width) 0 $color,
        0 $width 0 $color,
        $width (-$width) 0 $color,
        $width 0 0 $color,
        $width $width 0 $color
    );
}
