@import 'src/styles/library/index';

.Tray {
    @include tray;

    position: absolute;
    bottom: -1px;
    width: 100%;

    @mixin iconButton {
        $size: vw(50px);

        @include buttonIconOnlyBase;

        position: relative;
        visibility: visible;
        width: $size !important;
        height: $size !important;
        pointer-events: all;
        border: 1px solid white !important;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
        color: white;
    }

    & .general,
    & .justMe {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    & .general {
        height: vw($trayHeight);
    }

    & .justMe {
        padding: 0 0 vw(2 * $basePadding);

        & button {
            @include dynamicButton;
        }
    }

    & .hcp {
        font-size: 1.7rem;
        word-break: keep-all;
        white-space: normal;
        max-height: 2em;
        overflow: hidden;
        text-align: center;
    }

    & .player {
        display: flex;
        font-size: 1.7rem;
        word-break: break-word;
        white-space: normal;
        max-height: 2em;
        overflow: hidden;
    }

    & > * {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        white-space: nowrap;
    }

    & .isMe,
    & .isDummy {
        margin: {
            top: 0.1em;
            left: $basePadding;
        }
        font: {
            size: 0.8em;
        }
    }

    & .actions {
        display: flex;
        margin-right: vw(-15px);

        & button {
            position: relative;
            color: $colorFontInverted;
            font: {
                size: vw(24px);
            }
        }
    }

    & .bridgePosition {
        margin: {
            right: 5%;
        }
        font: {
            size: vw(16px * 1.6);
            weight: 700;
        }
    }

    & .toggleShowCards {
        @include buttonIconOnly($icon-flip);
    }

    & .rotateTable {
        @include buttonIconOnly($icon-eye);
    }

    & .isActive {
        @include buttonIconOnly($icon-loop2, 1.2em);

        display: none;
        margin: {
            left: $basePadding/2;
        }
        color: $colorFontInverted;
        cursor: default;
        animation: 4s rotateRight infinite linear;
        @keyframes rotateRight {
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &.isHighlighted {
        &.currentPlayerDisplay {
            &-background {
                &:after {
                    background: inherit;
                }
            }

            &-border {
                &:after {
                    border: {
                        width: 2px;
                        style: solid;
                        color: $colorFontInverted;
                    }
                }
            }

            &-animation {
                & .isActive {
                    display: block;
                }
            }
        }
    }

    & button {
        &.manualDeal {
            @include dynamicButton;
        }

        &.showInvite,
        &.sendInvite,
        &.closeInvite {
            @include dynamicButton;
            @include iconButton;
        }

        &.showInvite {
            font-size: 1.7em;
            font-weight: 400;
        }

        &.sendInvite {
            @include buttonIconOnlyIcon($icon-paperplane);

            margin-right: px(5);
        }

        &.closeInvite {
            @include buttonIconOnlyIcon($icon-cross);

            font-size: 0.8em;
        }
    }

    & .invite {
        position: relative;

        & .input {
            position: absolute;
            bottom: 0.3em;
            right: 100%;
            margin-right: px(5);

            & .storedEmails {
                max-height: 6em;
                overflow-x: auto;
            }

            & .storedEmail {
                background: black;
                border: 1px solid white;
                padding: 0.4em 0.5em;
            }
        }

        & input {
            @include dynInput;
            width: vw(310px);
            background: black;
            color: white;
            padding: 0 0.5em;

            &.invalid {
                color: red;
            }
        }
    }

    & .info {
        @include centerHV;

        position: absolute;
        height: vw(50px);
        font-size: vw(25px);

        & .board {
            display: flex;
            align-items: baseline;

            & .boardLabel {
                font-weight: bold;
                margin-right: vw(5px);
            }

            & .boardValue {
                font-size: 0.8em;
            }
        }
    }

    & .timeToGameEnd {
        min-width: vw(60px);
        margin: {
            top: vw(5px);
        }
    }

    & .buttonsCenter {
        width: 100%;

        & button {
            margin: 0 vw($basePadding/2);

            &.next,
            &.prev {
                @include iconButton;
            }

            &.next {
                @include buttonIconOnlyIcon($icon-chevron-right);

                &:before {
                    margin: {
                        top: vw(1px);
                        left: vw(1px);
                    }
                }
            }

            &.prev {
                @include buttonIconOnlyIcon($icon-chevron-left);

                &:before {
                    margin: {
                        top: vw(1px);
                        left: vw(-1px);
                    }
                }
            }
        }
    }

    & .buttonsRight {
        & button {
            @include iconButton;

            margin: {
                left: vw(15px);
            }

            &.false {
                &:after {
                    @include centerHV;

                    content: '';
                    position: absolute;
                    width: 70%;
                    height: vw(2px);
                    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
                    background: white;
                    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                    transform-origin: center center;
                }
            }
        }

        & .micro {
            @include buttonIconOnlyIcon($icon-microphone);

            //&.false {
            //  @include buttonIconOnlyIcon($icon-microphone-slash);
            //}
        }

        & .sound {
            @include buttonIconOnlyBase;
            @include buttonIconOnlyIcon($icon-volume-medium);

            //&.false {
            //  @include buttonIconOnlyIcon($icon-volume-mute2);
            //}
        }

        & .camera {
            @include buttonIconOnlyIcon($icon-video);
        }

        & .showMetaData {
            @include buttonIconOnlyIcon($icon-info);

            visibility: visible;
            pointer-events: all;
        }

        & .showSettings {
            @include buttonIconOnlyIcon($icon-settings, vw(26px));
        }
    }

    .Hand.fan + & {
        margin: {
            top: vw(-20px);
        }
    }
}
