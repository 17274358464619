@import 'src/styles/library/index';

.TrickHistory {
    $trickWidth: 100 / 3 * 1%;
    $trickHeight: 120px;
    $padding: 1px;
    $tricksPerRow: 4;
    $fontSize: 24px;

    z-index: 100;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    counter-reset: section;

    & .tricks {
        display: flex;
        flex: 1 1 auto;
        overflow: auto;
        flex-wrap: wrap;
    }

    & .bridgePositions,
    & .trick {
        @include aspectRatio(
            (
                100: 85
            ),
            false
        );

        position: relative;
        flex: 0 0 $trickWidth;
        border: {
            width: 1px;
            style: solid;
            color: black;
        }
        background: white;

        & > div {
            $padding: 2px;

            position: absolute;
            text-transform: uppercase;
            padding: vw($padding);
            font: {
                size: 22px;
                weight: 700;
            }

            &.top {
                @include centerH;

                top: 0;
            }

            &.right {
                @include centerV;

                right: 0;
            }

            &.bottom {
                @include centerH;

                bottom: 0;
            }

            &.left {
                @include centerV;

                left: 0;
            }
        }
    }

    & .trick {
        position: relative;

        & .card {
            @include suits;

            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            font-family: Roboto;
            letter-spacing: vw(-2.5px);
            padding: {
                right: vw(3px);
            }

            &:before {
                display: inline-block;
                margin: {
                    left: vw(3px);
                }
                font-size: 0.8em;
            }

            & span {
                color: black;
                font-weight: 500;
            }

            &:after {
                margin: {
                    left: vw(2px);
                }
                font: {
                    size: 0.8em;
                }
            }

            &.leader {
                border: {
                    width: 1px;
                    style: solid;
                    color: black;
                }
            }
        }

        & .trick-index {
            position: absolute;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            width: vw($fontSize * 1.2);
            height: vw($fontSize * 1.2);
            max-width: 26px;
            max-height: 26px;
            font: {
                size: vw($fontSize * 0.75);
                weight: 600;
            }
            background: black;
            color: $colorFontInverted;

            &:before {
                counter-increment: section;
                content: counter(section);
            }
        }

        &:after {
            font-size: 16px;
        }

        &.winner {
            $triangleWidth: 13px;
            $triangleHeight: $triangleWidth;
            $triangleColor: black;
            $margin: 10px;

            background: lighten(darkseagreen, 18);

            &-top,
            &-right,
            &-bottom,
            &-left {
                &:after {
                    @include centerVH;

                    content: '';
                    position: absolute;
                    z-index: 2;
                }
            }

            &-top {
                &:after {
                    @include icon($icon-chevron-up);
                }
            }

            &-right {
                &:after {
                    @include icon($icon-chevron-right);
                }
            }

            &-bottom {
                &:after {
                    @include icon($icon-chevron-down);
                }
            }

            &-left {
                &:after {
                    @include icon($icon-chevron-left);
                }
            }
        }
    }
}
