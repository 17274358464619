@import 'src/styles/library/index';

.ShortTable {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    & .displayPosition {
        padding: 0 vw(10px) vw(30px);
    }

    & h3 {
        $height: 30px;

        height: $height;
        border-radius: $height/2 $height/2 0 0;
        padding: 0 $height;
        font-size: $height/2;
        text-align: center;
        text-transform: uppercase;
        line-height: $height;
        background-image: radial-gradient(ellipse farthest-corner at center 40%, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.7) 100%);
        background-color: var(--app-background);
        color: white;
    }

    & .middle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & .left {
            padding-left: 0;
        }

        & .right {
            padding-right: 0;
        }
    }

    & .Hand {
        --fontSize: calc(var(--scale) * 16px);

        box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
        width: calc(var(--scale) * 150px);

        &.stacked {
            & .innerHand {
                margin: 0;
                padding-bottom: 7px;
            }

            & .suit {
                position: relative;

                &:empty {
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        top: 50%;
                        left: 30px;
                        width: 0.3em;
                        height: 3px;
                        background: black;
                    }
                }

                & > div {
                    width: auto;
                    height: 1em;
                    font-size: var(--fontSize);
                }

                &:before {
                    font-size: var(--fontSize);
                }
            }
        }
    }
}
