@import 'src/styles/library/index';

.GameResults {
    $hoverColor: yellowgreen;

    display: block;

    & * {
        font-family: 'Roboto', sans-serif;
    }

    & .header {
        position: relative;
        z-index: 10;
        display: grid;
        grid-template-columns: auto 1fr;

        & .back {
            @include buttonIconOnly($icon-chevron-left);

            align-self: center;
        }
    }

    & h2 {
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        padding: {
            left: 2em;
        }
        font-size: 26px;

        & > * {
            margin-left: 1em;
        }
    }

    & thead {
        border-bottom: 1px solid black;

        & tr {
            &:first-child {
                & th {
                    text-align: center;
                }
            }
        }
    }

    & th,
    & td {
        padding: 10px 17px;
        font-size: 16px;
        text-align: left;
        vertical-align: center;

        &.hide {
            display: none;
        }
    }

    & .AuctionBox {
        & .auction {
            & th,
            & .setWidths td {
                padding: 0;
            }
        }
    }

    & th {
        font: {
            size: 18px;
            weight: bold;
        }
    }

    & td {
        &.contract,
        &.dir,
        &.lead,
        &.declarer {
            text-transform: uppercase;
        }
    }

    & button {
        $baseButtonHeight: 30px;

        @include button;

        height: convert($baseButtonHeight);
        min-height: convert($baseButtonHeight);
        padding: 0 convert(20px);
        color: black;
        white-space: nowrap;
        font-size: convert(16px, false);
    }

    & span.link {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    & .gameResultsScores,
    & .gameResultsPair,
    & .gameResultsBoards {
        & tbody {
            & tr {
                cursor: pointer;

                &:nth-child(odd) {
                    background: white;
                }

                &:nth-child(even) {
                    background: lightgray;
                }

                &.isMe {
                    background: yellow;
                }

                &:hover,
                &.selected {
                    background: $hoverColor;
                }
            }
        }
    }

    & .gameResultsScores {
        & h2 {
            text: {
                align: left;
                transform: uppercase;
            }
        }

        & table {
            margin: {
                bottom: 36px;
                left: auto;
                right: auto;
            }
        }
    }

    & .gameResultsBoards {
        margin: {
            bottom: 36px;
        }

        & h2 {
            text-align: left;
        }
    }

    & .gameResultsPair {
        & th,
        & td {
            padding: 10px 20px;
        }
    }

    & .gameResultsPair,
    & .gameResultsBoards {
        $suitFontSize: 0.9em;

        & .suit {
            @include suits;

            margin: {
                left: 5px;
            }

            & .rank {
                margin-left: 0.1em;
                color: black;
            }

            &:before {
                font-size: $suitFontSize;
            }
        }

        & .contract {
            &.suit {
                margin: {
                    left: 3px;
                }

                & .level {
                    color: black;
                }

                &:before {
                    font-size: $suitFontSize;
                }
            }
        }

        & .lead {
            font: {
                size: 16px;
            }

            & .suit {
                margin: {
                    right: 3px;
                }
            }
        }

        &.v2 {
            & .contract {
                display: flex;

                &:before {
                    order: 2;
                    margin: 0 0.2em;
                }

                & .notrump {
                    order: 1;
                    margin: 0 0.2em;
                }

                & .x {
                    order: 3;
                }
            }
        }

        &.hidden {
            display: none;
        }
    }

    & .showBoardReview {
        position: relative;

        & .auctionBox-trickHistory h3 {
            display: none;
        }

        & .close-board-review {
            position: absolute;
            top: 0;
            left: 100%;

            @include buttonIconOnlyBase;
            @include buttonIconOnlyIcon($icon-cross, vw(16px));
        }
    }

    & .select-board {
        $itemHeight: 40px;

        position: relative;
        height: $itemHeight;

        & > div {
            display: none;
            justify-content: center;
            align-items: center;
            height: $itemHeight;
            background: white;
            font: {
                weight: 400;
                size: 0.8em;
            }
            padding: 0.2em;
            white-space: nowrap;
            cursor: pointer;

            &.current-board {
                display: flex;

                &:after {
                    font-family: icomoon;
                    content: '#{$icon-chevron-down}';
                    font-size: 0.8em;
                    margin-left: 0.4em;
                }
            }
        }

        &.opened {
            & > div {
                display: flex;
                border: {
                    style: solid;
                    width: 1px 1px 0 1px;
                    color: black;
                }

                &:hover,
                &.current-board {
                    background: $hoverColor;
                }
            }
        }

        &:not(.opened) {
            & .current-board {
                border: 1px solid black;
            }
        }
    }

    & .select-comparison {
        max-height: 46px;

        & .gameResultsBoards {
            & thead {
                display: none;
            }

            & tbody {
                border: 1px solid black;

                & tr {
                    &:nth-child(1n + 25) {
                        display: none;
                    }

                    &:after {
                        visibility: hidden;
                        margin: {
                            right: 0.2em;
                            left: 0.4em;
                        }
                        font-family: icomoon;
                        content: '#{$icon-chevron-down}';
                        font-size: 0.6em;
                    }
                }
            }

            & td {
                padding: 10px 3px;
                font: {
                    size: 0.8em;
                    weight: 400;
                }

                &:nth-child(1),
                &:nth-child(1n + 9) {
                    display: none;
                }

                &.name {
                    & span {
                        @include ellipsis;

                        display: block;
                        max-width: 200px;
                        text-decoration: none;
                        pointer-events: none;
                    }
                }
            }

            &.hidden {
                display: block;
            }
        }

        &:not(.opened) {
            & table {
                //border: 1px solid black;
            }

            & .gameResultsBoards {
                & tr {
                    &:not(.selected) {
                        display: none;
                    }

                    &.selected {
                        background: white;

                        &:after {
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}
