@import 'src/styles/library/index';

.TrickCount {
    @include tray;

    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: vw(20px);

    & .trickCount,
    & .contractStake {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 0 0 auto;
        font: {
            size: vw(24px);
        }
    }

    & .trickCount {
        position: relative;
        z-index: 2;
        height: vw(60px);
        white-space: pre;
    }

    & .contractStake {
        position: relative;
        z-index: 2;

        & > div {
            display: flex;
        }
    }

    & .contract,
    & .stake {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    & .Call {
        display: flex;
        justify-content: center;
        align-items: center;
        width: vw(40px);
        height: vw(24px);
    }

    & .contract {
        & .Call {
            background: white;

            & svg {
                flex: 0 0 100%;
            }

            &:not(.noTrump) {
                padding: {
                    left: vw($basePadding);
                }
            }
        }
    }

    & .stake {
        & > section {
            border: 1px solid white;
        }
    }

    & .declarer {
        margin: {
            right: vw($basePadding);
        }
        font-size: vw(24px);

        &:after {
            content: ':';
        }
    }
}
