@import 'src/styles/library/index';

.Jitsi {
    @include aspectRatio(
        (
            16: 9
        )
    );

    position: absolute;
    z-index: $zIndexVideo;
    top: 0;
    right: 0;
    bottom: 0;
    left: vw(10px);

    & .innerJitsi {
        height: 100%;
        width: 100%;

        & iframe {
            width: 100% !important;
            height: 100% !important;
        }
    }

    & .toggleTileView {
        $size: vw(50px);

        @include dynamicButton;
        @include buttonIconOnly($icon-users);

        position: absolute;
        top: auto;
        right: $basePadding;
        bottom: $basePadding;
        left: auto;
        width: $size !important;
        height: $size !important;
        border: 1px solid white !important;
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
        color: white;
    }

    &.fullSize {
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: $zIndexSettings - 10;
        transform: none;
    }

    &.hidden {
        display: none;
    }

    .Seat.top.isDummy ~ & {
        width: vw(270px);
    }

    .Table.generalLayout-v_impaired .Seat ~ & {
        width: vw(420px);
    }

    .Table.generalLayout-v_impaired .Seat.top.isDummy ~ & {
        width: vw(270px);
    }
}
