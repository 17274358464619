@import 'src/styles/library/index';

._Seat {
    $cardMovementRatio: 3;
    $myCardsBidSize: 140px;
    $myCardsPlaySize: 170px;

    position: absolute;
    z-index: $zIndexHands;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 vw($trayHeight/2);
    background: {
        size: 0 0;
        image: inherit;
    }

    @mixin handTrayDimensions($cardsSize: 100px) {
        & .Card {
            width: vw($cardsSize);

            .isNotMobile & {
                &.active {
                    &:hover {
                        & .innerCard {
                            top: vw(-$cardsSize/$cardMovementRatio);
                        }
                    }
                }
            }

            &.selected {
                & .innerCard {
                    top: vw(-$cardsSize/$cardMovementRatio);
                }
            }
        }

        & .Hand {
            &.straight {
                padding: {
                    right: vw($cardsSize * $baseStraightHandOverlapRatio / 2);
                    left: vw($cardsSize * $baseStraightHandOverlapRatio / 2);
                }

                & .Card {
                    margin: {
                        right: vw(-$cardsSize * $baseStraightHandOverlapRatio / 2);
                        left: vw(-$cardsSize * $baseStraightHandOverlapRatio / 2);
                    }
                }
            }

            &.fan {
                & .Card {
                    margin: {
                        right: vw(-$cardsSize / 2);
                        left: vw(-$cardsSize / 2);
                    }
                }
            }
        }

        & .Tray {
            width: vw(13 * $cardsSize - 12 * $cardsSize * $baseStraightHandOverlapRatio + $trayHeight);
        }
    }

    &.top {
        @include centerH;
        @include handTrayDimensions($baseCardWidth);

        top: vw($baseCardWidth/$cardMovementRatio);

        .gamePhase-PLAY & {
            &.isDummy {
                @include handTrayDimensions($myCardsPlaySize);

                & .Hand {
                    height: vw(1.5 * $myCardsPlaySize);
                    padding: {
                        top: vw($myCardsPlaySize/$cardMovementRatio);
                    }
                    overflow: hidden;

                    &.fan {
                        height: vw(1.6 * $myCardsPlaySize);
                        width: vw(950px);

                        & + .Tray {
                            margin: {
                                top: vw(-40px);
                            }
                        }
                    }
                }

                top: 0;
            }
        }
    }

    &.right {
        @include centerV;
        @include handTrayDimensions($baseCardWidth);

        right: 0;
        margin: {
            top: vw(-70px);
        }
    }

    &.bottom {
        @include centerH;
        @include handTrayDimensions($myCardsBidSize);

        bottom: vw(-$trayBottom);
        transition: $baseTransition;

        & .Tray {
            margin: {
                top: vw(-80px);
            }
        }

        .gamePhase-PLAY &,
        .gamePhase-END & {
            @include handTrayDimensions($myCardsPlaySize);

            & .Tray {
                margin: {
                    top: vw(-120px);
                }
            }

            & .Hand.fan {
                & + .Tray {
                    margin: {
                        top: vw(-80px);
                    }
                }
            }
        }

        .gamePhase-DEAL & {
            @include handTrayDimensions();
        }
    }

    &.left {
        @include centerV;
        @include handTrayDimensions($baseCardWidth);

        left: 0;
        margin: {
            top: vw(-70px);
        }
    }
}

.Seat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;
}
