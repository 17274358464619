@import 'src/styles/library/index';

.Poll {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: vw(5px) vw(10px);
    color: white;
    overflow: auto;
    & .question {
        font-size: vw(28px);
        font-weight: 100;
    }

    & .answers {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    & button {
        @include dynamicButton;

        display: block;
        height: auto;
        margin-top: vw(20px);
        margin-left: vw(20px);
        float: left;
    }
}
