@import 'src/styles/library/index';

.Login {
    @include watermark;

    position: absolute;
    z-index: $zIndexLogin;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $colorFontInverted;

    & input[autoComplete='on'] {
        position: fixed;
        top: -4000px;
        left: -4000px;
    }

    & > form {
        position: relative;
        width: 400px;
    }

    & h1 {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: {
            bottom: 4 * $basePadding;
        }
        font-size: 24px;

        & a {
            font: {
                size: 16px;
                weight: 400;
            }
            text-decoration: underline;
            cursor: pointer;
            color: $colorFontInverted;

            &:hover {
                text-decoration: none;
            }
        }
    }

    & .actions {
        display: flex;
        justify-content: flex-end;
    }

    & .error {
        margin: {
            bottom: 3 * $basePadding;
        }
        padding: $basePadding;
        border: {
            radius: $baseBorderRadius;
            width: 2px;
            style: solid;
            color: white;
        }
        background: $colorRed;
        color: white;
        font: {
            size: 18px;
            weight: 600;
        }
    }

    & .f2f {
        font-size: vw(24px);

        & .bridgePositions {
            display: flex;

            & > * {
                margin-right: vw(30px);
            }
        }

        & .bridgePosition {
            margin: vw(30px) 0;

            & label {
                display: inline-flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                text-transform: capitalize;
                margin: vw(10px);
                cursor: pointer;

                & input {
                    display: none;

                    & + span {
                        $size: 30px;

                        position: relative;
                        height: vw($size);
                        width: vw($size);
                        margin-right: vw($size/2);

                        &:before,
                        &:after {
                            @include centerHV;

                            content: '';
                            position: absolute;
                            display: block;
                            border-radius: 50%;
                        }

                        &:before {
                            width: vw($size);
                            height: vw($size);
                            border: vw(4px) solid white;
                        }

                        &:after {
                            $size: vw(10px);

                            width: $size;
                            height: $size;
                        }
                    }

                    &:checked {
                        & + span {
                            &:after {
                                background-color: white;
                            }
                        }
                    }
                }

                &:first-of-type {
                    margin-top: vw(20px);
                }
            }
        }
    }

    & .fnli {
        display: grid;
        grid-template-columns: 1fr 25%;
        grid-template-rows: auto auto;

        & h4 {
            font-size: vw(30px);
            grid-column: 1/3;
            grid-row: 1/2;
            margin-bottom: vw(10px);
        }

        & .firstName {
            margin-right: vw(20px);
        }
    }
}
