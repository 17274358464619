@import 'src/styles/library/index';

.Tricks {
    position: absolute;
    z-index: $zIndexHands - 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.hideClosedTricks {
        & .closed {
            display: none;
        }
    }
}
