@import 'src/styles/library/index';

@mixin getHandRatios($set) {
    $cardRatio: getCardData($set, ratio);
    $cardOverlap: getCardData($set, overlap);
    $cardX: nth($cardRatio, 1);
    $cardY: nth($cardRatio, 2);
    //$handX: $cardX * (13 * (1 - $cardOverlap));
    $handX: $cardX * (12 * (1 - $cardOverlap) + 1);

    //@include aspectRatio(($handX:$cardY));
    //@include aspectRatio((2:1));

    & .Card {
        $cardWidth: $cardX / $handX * 100%;
        $top: getCardData($set, hover) * 100%;

        width: $cardWidth;
        margin: {
            top: $top;
            bottom: 0;
        }
        transition: $baseTransition;

        // TODO: only large scale
        //transform: translateX(#{$cardOverlap * 50%});

        & + .Card {
            margin: {
                left: -$cardOverlap * $cardWidth;
            }
        }

        &.active:hover {
            margin: {
                top: 0;
                bottom: $top;
            }
        }

        &.raised {
            & .innerCard {
                background: #ffff00;
            }
        }
    }
}

.Hand {
    background: {
        size: 0;
    }
    overflow: visible;

    & .innerHand {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background: inherit;
    }

    & .Card {
        flex: 0 0 auto;
    }

    &.straight {
        @include getHandRatios(straight);
    }

    &.fan {
        //$rotate: 45deg / 13;
        $rotate: 3deg;

        @include getHandRatios(fan);

        //margin: {
        //    bottom: 4%;
        //}
        overflow: hidden;

        & .Card {
            transform-origin: center 500%;

            & + .Card {
                margin-left: -24%;
            }
        }

        $cardRotations: (
            1: (
                0
            ),
            3: (
                -1 * $rotate,
                0,
                1 * $rotate
            ),
            5: (
                -2 * $rotate,
                -1 * $rotate,
                0,
                1 * $rotate,
                2 * $rotate
            ),
            7: (
                -3 * $rotate,
                -2 * $rotate,
                -1 * $rotate,
                0,
                1 * $rotate,
                2 * $rotate,
                3 * $rotate
            ),
            9: (
                -4 * $rotate,
                -3 * $rotate,
                -2 * $rotate,
                -1 * $rotate,
                0,
                1 * $rotate,
                2 * $rotate,
                3 * $rotate,
                4 * $rotate
            ),
            11: (
                -5 * $rotate,
                -4 * $rotate,
                -3 * $rotate,
                -2 * $rotate,
                -1 * $rotate,
                0,
                1 * $rotate,
                2 * $rotate,
                3 * $rotate,
                4 * $rotate,
                5 * $rotate
            ),
            13: (
                -6 * $rotate,
                -5 * $rotate,
                -4 * $rotate,
                -3 * $rotate,
                -2 * $rotate,
                -1 * $rotate,
                0,
                1 * $rotate,
                2 * $rotate,
                3 * $rotate,
                4 * $rotate,
                5 * $rotate,
                6 * $rotate
            ),
            2: (
                -0.5 * $rotate,
                0.5 * $rotate
            ),
            4: (
                -1.5 * $rotate,
                -0.5 * $rotate,
                0.5 * $rotate,
                1.5 * $rotate
            ),
            6: (
                -2.5 * $rotate,
                -1.5 * $rotate,
                -0.5 * $rotate,
                0.5 * $rotate,
                1.5 * $rotate,
                2.5 * $rotate
            ),
            8: (
                -3.5 * $rotate,
                -2.5 * $rotate,
                -1.5 * $rotate,
                -0.5 * $rotate,
                0.5 * $rotate,
                1.5 * $rotate,
                2.5 * $rotate,
                3.5 * $rotate
            ),
            10: (
                -4.5 * $rotate,
                -3.5 * $rotate,
                -2.5 * $rotate,
                -1.5 * $rotate,
                -0.5 * $rotate,
                0.5 * $rotate,
                1.5 * $rotate,
                2.5 * $rotate,
                3.5 * $rotate,
                4.5 * $rotate
            ),
            12: (
                -5.5 * $rotate,
                -4.5 * $rotate,
                -3.5 * $rotate,
                -2.5 * $rotate,
                -1.5 * $rotate,
                -0.5 * $rotate,
                0.5 * $rotate,
                1.5 * $rotate,
                2.5 * $rotate,
                3.5 * $rotate,
                4.5 * $rotate,
                5.5 * $rotate
            )
        );

        @each $cards, $rotations in $cardRotations {
            &.has-#{$cards} {
                & .Card {
                    $index: 0;
                    @each $rotation in $rotations {
                        $index: $index + 1;
                        &:nth-child(#{$index}) {
                            transform: rotate(#{$rotation});
                        }
                    }
                }
            }
        }
    }

    &.stacked {
        & .innerHand {
            $padding: 1 * $basePadding;
            $shiftBottom: 3 * $basePadding;
            $radius: 2 * $basePadding;

            flex-direction: column;
            background: rgba(255, 255, 255, 0.7);
            padding: {
                top: vw($padding);
                right: vw($padding);
                bottom: vw($padding + $shiftBottom);
                left: vw($padding);
            }
            margin: {
                bottom: vw(-$shiftBottom);
            }
            border: {
                radius: vw($radius) vw($radius) 0 0;
            }
        }

        & .suit {
            @include suits;

            display: flex;
            align-items: center;
            text-transform: uppercase;

            &:before {
                margin: {
                    right: vw($basePadding);
                }
                font: {
                    size: vw(30px);
                }
            }

            & > div {
                $size: 45px;

                display: flex;
                justify-content: center;
                align-items: center;
                width: vw($size);
                height: vw($size);
                color: black;
                font: {
                    size: vw($size * 0.7);
                    weight: 700;
                }
                border: {
                    //width: 1px;
                    style: solid;
                    radius: vw(5px);
                    //radius: 50%;
                }
                pointer-events: none;

                .Table.gamePhase-PLAY & {
                    cursor: pointer;
                    pointer-events: all;
                    //box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);

                    &:hover {
                        box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3) inset;
                    }
                }
            }

            & + .suit {
                margin: {
                    top: vw($basePadding/4);
                }
            }
        }

        & .raised {
            background-color: yellow;
        }
    }
}
