$baseTransitionTime: #{var(--transition-time)};
$baseTransition: all $baseTransitionTime ease-in-out;
$baseBorderRadius: 4px;
$basePadding: 10px;
$baseCardWidth: 120px;
$baseCardBorderRadius: 7px;
$baseStraightHandOverlapRatio: 0.7;
$suits: (diamonds, hearts, spades, clubs);
$ranks: (ace, king, queen, jack, ten, nine, eight, seven, six, five, four, three, two);
$dynamicBase: 1400;
$dynamicRatioWidth: 2048;
$dynamicRatioHeight: 1330;

$leftRightScale: 0.7;
$trayHeight: 60px;
$trayBottom: -10px;

// Buttons
$baseButtonHeight: 40px;

// z-indexes
$zIndexLogin: 1;
$zIndexVideo: 100;
$zIndexHands: 200;
$zIndexSettings: 400;
$zIndexModal: 500;

$ranks: (ace, king, queen, jack, ten, nine, eight, seven, six, five, four, three, two);

$cardData: (
    straight: (
        ratio: (
            122,
            191
        ),
        overlap: 0.7,
        hover: 0.05
    ),
    fan: (
        ratio: (
            122,
            191
        ),
        overlap: 0.7,
        hover: 0.05
    )
);

@function getCardData($set, $key) {
    $set: map-get($cardData, $set);
    @return map-get($set, $key);
}
