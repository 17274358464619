@mixin buttonDisabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed !important;
}

@function convert($px, $dynamic: false) {
    @if ($dynamic == true) {
        @return vw($px);
    } @else {
        @return $px;
    }
}

@mixin buttonBase($dynamic: false) {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: convert($baseButtonHeight, $dynamic);
    min-height: convert($baseButtonHeight, $dynamic);
    padding: 0 convert(3 * $basePadding, $dynamic);
    border: {
        width: 1px;
        style: solid;
        color: $colorFontInverted;
        radius: convert($baseBorderRadius * 2, $dynamic);
    }
    background: {
        color: transparent;
        image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
    }
    color: $colorFontInverted;
    font: {
        size: convert(20px, $dynamic);
        weight: 500;
    }
    font-family: 'Jost', sans-serif;
    text-align: center;
    outline: none;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;
    box-shadow: convert(5px, $dynamic) convert(5px, $dynamic) convert(10px, $dynamic) rgba(0, 0, 0, 0.5);

    &[disabled],
    &.disabled {
        @include buttonDisabled;
    }

    &:hover {
        box-shadow: none;
        background: {
            image: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
        }
    }
}

// NORMAL BUTTONS
@mixin button {
    @include buttonBase;
}

@mixin dynamicButton {
    @include buttonBase(true);
}

@mixin buttonWithIcon($icon, $iconPosition: left) {
    $iconSpace: $baseButtonHeight;

    position: relative;
    padding: {
        #{$iconPosition}: $iconSpace;
    }

    &:before {
        @include centerHV;

        @include icon($icon);

        position: absolute;
        z-index: 2;

        @if ($iconPosition == left) {
            left: $iconSpace/2;
        } @else {
            left: calc(100% - #{$iconSpace/2});
        }
    }
}

// ICON BUTTONS
@mixin buttonIconOnlyBase {
    transition: $baseTransition;
    border-radius: 50%;

    display: inline-block;
    width: vw($baseButtonHeight);
    height: vw($baseButtonHeight);
    padding: 0;
    background: none;
    color: $colorFont;
    line-height: 0;
    border: none;
    cursor: pointer;
    outline: none;

    &[disabled],
    &.disabled {
        @include buttonDisabled;
    }
}

@mixin buttonIconOnlyIcon($icon, $iconSize: null) {
    @if ($iconSize == null) {
        font-size: vw($baseButtonHeight * 0.7);
    } @else {
        font-size: $iconSize;
    }

    &:before {
        @include centerHV;

        @include icon($icon);

        position: absolute;
    }
}

@mixin buttonIconOnly($icon, $iconSize: null) {
    @include buttonIconOnlyBase;
    @include buttonIconOnlyIcon($icon, $iconSize);
}

@mixin resetButtonToIconOnly {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
}
