@import 'src/styles/library/index';

.ManualDeal {
    & .suits {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    & .suit {
        $cardWidth: 100 / 8 * 1%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: {
            grow: 0;
            shrink: 0;
            basis: vw(600px);
        }
        margin: {
            top: 1%;
        }
        padding: {
            left: $cardWidth/4;
            right: $cardWidth/4;
        }

        & .card {
            position: relative;
            flex: 0 0 $cardWidth;
            margin: {
                left: -$cardWidth/4;
                right: -$cardWidth/4;
            }
            cursor: pointer;
        }
    }

    &.has-0,
    &.has-1,
    &.has-2,
    &.has-3,
    &.has-4,
    &.has-5,
    &.has-7,
    &.has-8,
    &.has-9,
    &.has-10,
    &.has-11,
    &.has-12 {
        & .card {
            cursor: pointer;
        }
    }

    & .Card {
        width: 100%;
    }
}
