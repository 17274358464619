@import 'src/styles/library/index';

.TableSlot {
    _overflow: hidden;

    & .innerTableSlot {
        @include centerHV;

        position: absolute;
        width: 100%;
        max-height: 100%;
        overflow: hidden;
        min-height: 40%;
    }

    .showTableSlotBorders & {
        border: 1px dashed white;
    }
}
