@import 'src/styles/library/index';

.BiddingBox {
    $biddingCellWidth: 50px;
    $biddingCellHeight: 50px;

    @mixin biddingCell {
        position: relative;
        display: table-cell;
        height: 100 / 7 * 1%;
        vertical-align: middle;
        border: 1px solid black;
        cursor: pointer;

        &:after {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        &:hover,
        &.highlighted {
            &:after {
                content: '';
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5) inset;
            }
        }

        &.disabled {
            cursor: not-allowed;

            &:after {
                content: '';
                background: rgba(0, 0, 0, 0.5);
            }

            &:hover {
                &:after {
                    box-shadow: none;
                }
            }
        }
    }

    @mixin biddingTable {
        display: table;
        border-collapse: collapse;
    }

    width: 100%;
    height: 100%;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);

    & .biddingRow {
        display: table-row;
    }

    & .bids {
        @include biddingTable;

        table-layout: fixed;
        width: 100%;
        height: 100 / 8 * 7 * 1%;

        & .biddingCell {
            $ntWidth: 0.22;

            @include biddingCell;

            width: (1 - $ntWidth)/4 * 100%;
            background: white;
            overflow: hidden;

            & .Call {
                position: relative;
                left: 13%;
                width: 100%;
                height: 80%;
            }

            &:first-child {
                width: $ntWidth * 100%;

                & .Call {
                    width: 80%;
                }
            }
        }
    }

    & .biddingActions {
        @include biddingTable;

        width: 100%;
        height: 100 / 8 * 1%;

        & > div {
            display: table-row;
            height: 100%;

            & > div {
                @include biddingCell;

                width: 100 / 3 * 1%;
                height: 100%;

                & .Call {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                }
            }
        }

        &.doubleValid {
            &-1,
            &-2 {
                & .redouble {
                    display: none;
                }
            }

            &-4 {
                // valid XX, show XX only

                & .double {
                    display: none;
                }
            }
        }
    }

    & .selectedBid {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: $basePadding;
        background: white;

        & .Call {
            width: 70%;
            height: 30%;
            margin: {
                left: vw(60px);
            }

            &.noTrump,
            &.zero {
                margin: {
                    left: 0;
                }
            }

            &.zero {
                height: vw(80px);
                line-height: vw(80px);
                font-size: vw(36px);
            }
        }

        & .confirmCancel {
            display: flex;
            justify-content: space-between;
            width: 70%;
            margin: {
                top: vw(30px);
            }
        }

        & .confirm {
            @include buttonIconOnly($icon-checkmark);

            border: {
                color: green;
            }
            color: green;
        }

        & .cancel {
            @include buttonIconOnly($icon-cross);

            border: {
                color: $colorRed;
            }
            color: $colorRed;
        }

        & .confirm,
        & .cancel {
            $size: 80px;

            position: relative;
            width: vw($size);
            height: vw($size);
            border: {
                width: 2px;
                style: solid;
            }

            &:before {
                font-size: vw($size/2);
            }
        }
    }

    & .alertMessage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: {
            top: 3 * $basePadding;
        }

        & input {
            @include dynInput;

            width: 100%;
            margin: {
                top: $basePadding;
            }
            border: {
                color: black;
            }
            color: black;
        }
    }

    & .auctionAlert {
        &.highlighted {
            & .Call {
                background: $colorRed;
            }
        }
    }
}
