@import 'src/styles/library/index';
@import '~compass-mixins/lib/_animate.scss';

.AuctionBox {
    --cellHeight: 40px;
    $cellHeight: 40px;
    $theadShadowSize: 5px;

    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 450px;

    & .thead {
        position: relative;
        z-index: 2;
        flex: 0 0 auto;
        overflow: hidden;
        padding-bottom: $theadShadowSize;

        & .auction {
            box-shadow: 0 0 $theadShadowSize black;
        }
    }

    & .tbody {
        flex: 1 0 50%;
        overflow: auto;
        margin: {
            top: -$theadShadowSize;
        }

        & .auction {
            margin: {
                top: -2px;
            }
        }
    }

    & .auction {
        & th,
        & td {
        }
    }

    & .auction {
        width: 100%;
        table-layout: fixed;

        & td,
        & th {
            position: relative;
            width: 25%;
            height: vw($cellHeight);
            background: white;
            border: {
                width: 1px;
                style: solid;
                color: black;
            }
            text-align: center;
            vertical-align: middle;
            overflow: hidden;

            &:first-child {
                border-left-width: 1px;
            }
        }

        & th {
            color: $colorFont;
            line-height: vw($cellHeight);
            font: {
                weight: 500;
                size: vw(19px);
            }
            text-transform: capitalize;

            &.vulnerable {
                background: $colorRed;
                color: $colorFontInverted;
            }
        }

        & td {
            cursor: pointer;

            & .Call {
                @include centerHV;

                position: absolute;
                width: 100%;
                height: 100%;

                &.hearts,
                &.spades,
                &.clubs,
                &.diamonds {
                    left: 60%;
                }

                & div {
                    @include centerHV;

                    position: absolute;
                    white-space: nowrap;
                }
            }

            & svg {
                --width: 60%;

                @include centerHV;

                position: absolute;
                width: var(--width);
            }

            &.empty {
                background: none;

                &:not(:last-child) {
                    border: none;
                }

                &:last-child {
                    border: {
                        color: white;
                        width: 1px;
                    }

                    &:before {
                        content: '?';
                        color: $colorFontInverted;
                        font: {
                            weight: 700;
                            size: vw(24px);
                        }
                    }
                }
            }

            &.noTrump {
                --width: calc(0.8 * var(--width));
                padding: 0 vw(15px);
            }
        }

        & .setWidths {
            & td {
                visibility: hidden;
                height: 0;
            }
        }
    }

    & .Call {
        &.withAlert {
            &:after {
                content: '!';
                position: absolute;
                right: 15%;
                font-size: vw(34px);
                font-weight: 500;
            }

            &.zero {
                &:after {
                    right: 5%;
                    font-size: vw(34px);
                    font-weight: 500;
                }
            }
        }

        &.zero {
            font-size: vw(19px) !important;
            padding: 0 !important;
        }
    }

    &.hideAuctionBox {
        .gamePhase-PLAY & {
            display: none;
        }
        visibility: collapse;
    }
}
