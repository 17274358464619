@import 'src/styles/library/index';

.Card {
    position: relative;
    overflow: visible;
    background: {
        image: inherit;
        size: 0;
    }

    @for $i from 1 through 13 {
        &:nth-child(#{$i}) {
            & .innerCard {
                & > *,
                &:before {
                    transition-delay: $i * 10ms;
                }
            }
        }
    }

    & .innerCard {
        position: relative;
        top: 0;
        width: 100%;
        border: {
            radius: vw($baseCardBorderRadius);
            width: 1px;
            style: solid;
            color: black;
        }
        background: {
            image: inherit;
            size: 0 0;
            color: var(--card-color-background);
        }
        transition: top 300ms, background-color 300ms;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

        & > * {
            visibility: hidden;
            transition: visibility 0s;
        }

        &:before {
            content: '';
            position: absolute;
            visibility: visible;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: {
                image: inherit;
                position: center;
                repeat: no-repeat;
                size: contain;
            }
            transition: visibility 0s;
        }
    }

    // set colors
    @include suitColors;

    // set suits & ranks

    & svg {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        fill: $colorCardBackground;

        &:first-child {
            position: relative;
        }

        & > g {
            display: none;
        }
    }

    & .face {
        @include centerHV;

        position: absolute;
        border: {
            width: 1px;
            style: solid;
        }
    }

    & #base {
        display: block;

        & rect {
            display: none;
        }
    }

    @each $rank in $ranks {
        &.#{$rank} {
            & [id^='#{$rank}'],
            & [id*='#{$rank}'] {
                display: block;
            }
        }
    }

    &.set-0 {
        & .face {
            width: 70%;
        }
    }

    &.set-1 {
        & .face {
            width: 100% * 83 / 227;
            border: {
                radius: $baseCardBorderRadius;
            }
        }
    }

    &.show,
    &.raised {
        & .innerCard {
            & > * {
                visibility: visible;
            }

            &:before {
                visibility: hidden;
            }
        }
    }

    &.privateHighlighted {
        & .innerCard {
            //background-color: coral;
        }
    }
}
