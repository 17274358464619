@import 'src/styles/library/index';

.Table {
    @include tableGrid;
    @include watermark;

    position: relative;
    width: 100%;
    height: 100%;
    max-width: calc(100vh * 17 / 9);

    & > * {
        position: absolute;
    }

    & .pleaseWait {
        @include centerHV;

        color: white;
        font: {
            size: vw(30px);
        }
    }

    & .middle-center,
    & .bottom-right {
        & .innerTableSlot {
            height: 100%;
        }
    }

    & .middle-center {
        & .AuctionBox {
            @include centerH;
        }
    }

    & .bottom-center {
        & .innerTableSlot {
            top: auto;
            bottom: 0;
            left: 0;
            transform: none;

            & .Hand {
                &.straight {
                    margin: {
                        bottom: 5%;
                    }
                }

                &.fan {
                    margin: {
                        bottom: 15%;
                    }
                }
            }
        }
    }

    & .bottom-left {
        & .innerTableSlot {
            @include fullSize;

            width: auto;
            height: auto;
            bottom: 2%;
            left: 2%;
            right: 2%;
        }
    }

    & .top-right,
    & .top-left {
        & .innerTableSlot {
            @include fullSize;
        }
    }

    & .top-left {
        & .innerTableSlot {
            @include fullSize;
        }
    }

    &.gamePhase {
        &-DEAL {
            @include tableGrid(
                (
                    top: (
                        33,
                        33
                    ),
                    middle: (
                        33,
                        33
                    ),
                    bottom: (
                        33,
                        33
                    ),
                    left: (
                        50,
                        25
                    ),
                    center: (
                        50,
                        25
                    ),
                    right: (
                        50,
                        25
                    )
                )
            );
            @include tableIndexes(((1, 1, 3), (1, 2, 1), (1, 1, 1)));

            & .TableSlot {
                & .innerTableSlot {
                    top: auto;
                    bottom: 0;
                    left: 0;
                    min-height: vw(100px);
                    transform: none;
                }
            }

            & .AuctionBox {
                max: {
                    width: vw(350px);
                }
            }

            & .ManualDeal {
                z-index: 5;
            }

            & .middle-center {
                & .innerTableSlot {
                    $padding: $basePadding;

                    top: $padding;
                    right: $padding;
                    bottom: $padding;
                    left: $padding;
                    width: auto;
                    height: auto;
                }
            }
        }

        &-BID {
            @include tableGrid(
                (
                    top: (
                        32,
                        32
                    ),
                    middle: (
                        36,
                        36
                    ),
                    bottom: (
                        26,
                        26
                    ),
                    left: (
                        28,
                        49
                    ),
                    center: (
                        22,
                        37
                    ),
                    right: (
                        28,
                        49
                    )
                )
            );
            @include tableIndexes(((1, 4, 5), (3, 1, 3), (2, 2, 2)));

            & .bottom-right,
            & .middle-center {
                & .innerTableSlot {
                    @include fullSize;
                }
            }

            & .middle-center {
                & .AuctionBox {
                    padding: 15px 15px;
                }
                overflow: visible;
            }

            & .bottom-right {
                & .BiddingBox {
                    position: absolute;
                    top: vw(15px);
                    right: 0;
                    bottom: 0;
                    width: auto;
                    height: auto;
                    max-width: 450px;
                }
            }

            &.handLayout-fan {
                @include tableGrid(
                    (
                        top: (
                            31,
                            31
                        ),
                        middle: (
                            38,
                            38
                        ),
                        bottom: (
                            30,
                            30
                        ),
                        left: (
                            22,
                            45
                        ),
                        center: (
                            30,
                            35
                        ),
                        right: (
                            22,
                            45
                        )
                    )
                );
            }

            & .TableSymbol {
                $size: 50%;

                @include centerHV;

                position: absolute;
                width: $size;
                height: $size;
                max: {
                    width: $size;
                    height: $size;
                }
            }
        }

        &-PLAY {
            @mixin fullSizeCenter {
                & .middle-center {
                    &,
                    & .innerTableSlot {
                        @include fullSize;
                    }
                }
            }

            @include tableGrid(
                (
                    top: (
                        32,
                        32
                    ),
                    middle: (
                        36,
                        36
                    ),
                    bottom: (
                        20,
                        20
                    ),
                    left: (
                        35,
                        40
                    ),
                    center: (
                        30,
                        40
                    ),
                    right: (
                        35,
                        40
                    )
                )
            );
            @include tableIndexes(((2, 4, 5), (4, 1, 4), (3, 4, 2)));

            @include fullSizeCenter;

            & .top-center {
                top: -5%;
            }

            &.dummyIs {
                &-top,
                &-bottom {
                    @include tableGrid(
                        (
                            top: (
                                20,
                                20
                            ),
                            center: (
                                27,
                                35
                            )
                        )
                    );
                }

                &-right {
                    @include tableGrid(
                        (
                            middle: (
                                36,
                                45
                            ),
                            center: (
                                25,
                                35
                            )
                        )
                    );
                }

                &-left {
                    @include tableGrid(
                        (
                            middle: (
                                45,
                                36
                            ),
                            center: (
                                25,
                                35
                            )
                        )
                    );
                }

                &-top,
                &-bottom,
                &-right,
                &-left {
                    & .middle-center {
                        &,
                        & .innerTableSlot {
                            @include fullSize;
                        }
                    }
                }
            }

            & .top-left {
                & .AuctionBox {
                    padding: 10px 10px;
                    max-width: 450px;
                }
            }

            & .bottom-right {
                & .innerTableSlot {
                    @include fullSize;

                    display: flex;
                    align-items: flex-end;
                }
            }

            &.handLayout-fan {
                @include tableGrid(
                    (
                        top: (
                            32,
                            32
                        ),
                        middle: (
                            36,
                            36
                        ),
                        bottom: (
                            28,
                            28
                        ),
                        left: (
                            35,
                            40
                        ),
                        center: (
                            30,
                            40
                        ),
                        right: (
                            35,
                            40
                        )
                    )
                );

                @include fullSizeCenter;

                &.dummyIs {
                    &-top,
                    &-bottom {
                        @include tableGrid(
                            (
                                top: (
                                    30,
                                    30
                                ),
                                center: (
                                    28,
                                    36
                                ),
                                bottom: (
                                    30,
                                    30
                                )
                            )
                        );
                        @include fullSizeCenter;
                    }

                    &-right {
                        @include tableGrid(
                            (
                                middle: (
                                    36,
                                    45
                                ),
                                center: (
                                    25,
                                    40
                                ),
                                bottom: (
                                    25,
                                    25
                                )
                            )
                        );
                        @include fullSizeCenter;
                    }

                    &-left {
                        @include tableGrid(
                            (
                                middle: (
                                    45,
                                    36
                                ),
                                center: (
                                    25,
                                    40
                                ),
                                bottom: (
                                    25,
                                    25
                                )
                            )
                        );
                        @include fullSizeCenter;
                    }
                }
            }
        }

        &-END {
            @include tableGrid(
                (
                    top: (
                        33,
                        33
                    ),
                    middle: (
                        33,
                        33
                    ),
                    bottom: (
                        25,
                        25
                    ),
                    left: (
                        28,
                        45
                    ),
                    center: (
                        20,
                        35
                    ),
                    right: (
                        28,
                        45
                    )
                )
            );
            @include tableIndexes(((1, 1, 3), (1, 2, 1), (1, 1, 1)));

            & .TableSlot {
                & .innerTableSlot {
                    top: auto;
                    bottom: 0;
                    left: 0;
                    min-height: vw(100px);
                    transform: none;
                }
            }

            & .top-right {
                & .innerTableSlot {
                    top: 0;
                }
            }

            & .bottom-center {
                & .Hand {
                    &.straight {
                        margin: {
                            bottom: 15%;
                        }
                    }
                }
            }

            & .AuctionBox {
                max: {
                    width: vw(350px);
                }
            }

            & .middle-center {
                & .innerTableSlot {
                    $padding: $basePadding;

                    top: $padding;
                    right: $padding;
                    bottom: $padding;
                    left: $padding;
                    width: auto;
                    height: auto;
                }
            }

            & .bottom-right {
                & .innerTableSlot {
                    display: flex;
                    align-items: flex-end;
                }
            }

            & .bottom-left {
                & .innerTableSlot {
                    height: 100%;
                }
            }

            &.handLayout-fan {
                @include tableGrid(
                    (
                        top: (
                            33,
                            33
                        ),
                        middle: (
                            33,
                            33
                        ),
                        bottom: (
                            30,
                            30
                        ),
                        left: (
                            28,
                            45
                        ),
                        center: (
                            22,
                            35
                        ),
                        right: (
                            28,
                            45
                        )
                    )
                );

                & .Card {
                    margin-top: 0;
                }
            }
        }
    }

    &.handLayout-stacked {
        @include tableGrid(
            (
                top: (
                    27,
                    27
                ),
                middle: (
                    40,
                    40
                ),
                bottom: (
                    27,
                    27
                ),
                left: (
                    30,
                    40
                ),
                center: (
                    30,
                    40
                ),
                right: (
                    30,
                    40
                )
            )
        );

        & .top-center,
        & .middle-left,
        & .middle-right,
        & .bottom-center {
            & .innerTableSlot {
                height: 100%;
                display: flex;
                align-items: flex-end;
            }
        }

        & .Tray {
            position: relative;
        }

        & .middle-right,
        & .middle-left {
            & .suit {
                & > div {
                    $size: 37px;

                    width: vw($size);
                    height: vw($size);
                    font: {
                        size: vw($size * 0.7);
                    }
                }
            }
        }
    }

    & iframe.customContent {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 98;
    }
}
